"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formValueFromLocalState = formValueFromLocalState;
var _lodash = _interopRequireDefault(require("lodash"));
var _serverTransforms = require("./serverTransforms");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const rubyWords = require('@rubyapps/ruby-words');
const TYPE_KEY = 'componentName';
const rubyComponent = require('@rubyapps/ruby-component');
function formValueFromLocalState(selfState, isError) {
  if (selfState == undefined) {
    return undefined;
  }
  const propertiesWithoutPermissions_byType = this.propertiesWithoutPermissions_byType();
  const propertiesWithoutPermissions_byKey = this.propertiesWithoutPermissions_byKey();
  const coreProperties_byInstanceType = this.coreProperties_byInstanceType();
  const Components = require('@rubyapps/ruby-component-builder').Components;
  const formValue = _lodash.default.reduce(selfState.fields, (collector, fieldObject, key) => {
    //# iterate the instances,
    //# iterate the properties in the instances
    //
    const value = _lodash.default.get(fieldObject, 'value');
    const usedComponentNames = _lodash.default.uniq(value.childIds.map(id => value.instances[id]).map(instance => _lodash.default.get(instance, 'instanceProps.componentName.value') || _lodash.default.get(instance, 'instanceProps.instanceType')));
    const supportedPropsByComponent = usedComponentNames.reduce((result, componentName) => {
      const comp = Components[componentName];
      return _extends(result, {
        [componentName]: !comp ? [] : _lodash.default.flow(comp => rubyComponent.createElement(comp, {
          props: {}
        }), element => element.propTypes, Object.keys)(comp)
      });
    }, {});
    const newInstances = _lodash.default.mapValues(value.instances, instance => {
      const componentName = _lodash.default.get(instance, 'instanceProps.componentName.value');
      const instanceType = _lodash.default.get(instance, 'instanceProps.instanceType');
      const coreProperties_byKey = coreProperties_byInstanceType[instanceType];
      const coreProperties = _lodash.default.values(coreProperties_byKey);
      const componentProperties = propertiesWithoutPermissions_byType[componentName];
      const properties = coreProperties_byKey || componentProperties ? [].concat(componentProperties || [], coreProperties || []) : undefined;
      const instancePropUpdates = (properties || []).reduce((result, property) => {
        const {
          propKey,
          type
        } = property;
        const currValue = _lodash.default.get(instance, ['instanceProps', propKey, 'value']);
        const newPropValue = _lodash.default.flow(function applyDefault(value) {
          return value == null ? property.defaultValue : value;
        }, function applyDefaultToProp(value) {
          if (!_lodash.default.isNil(value) || !property.defaultToProp) {
            return value;
          } else {
            const referencedProperty = propertiesWithoutPermissions_byKey[property.defaultToProp];
            return rubyWords.keyify(_lodash.default.get(instance, ['instanceProps', referencedProperty.propKey, 'value']) || referencedProperty.defaultValue);
          }
        }, function parseNum(value) {
          return property.type === 'number' && !isNaN(value) ? parseFloat(value) : value;
        }, function applyTransform(value) {
          // "transform" can dynamically change the property value before converting to formJS.
          // We needed this for the token tagger "data_type" property, which could be:
          //   - "number", when the "limit" property is set to 1
          //   - ["number"], when the "limit" property is set to anything else
          // The function, if defined, is passed:
          //   - the current value for the property as determined by the code above
          //   - the property definition
          //   - the field instance (as a normal JS object)
          return property.transform ? property.transform(value, property, instance) : value;
        })(currValue);
        return _extends(result, currValue !== newPropValue && {
          [propKey]: {
            value: newPropValue
          }
        });
      }, {});
      const definedInstancePropKeys = properties && [].concat(properties.map(propertyDef => propertyDef.propKey), Object.keys(coreProperties_byKey), 'instanceType');
      const formComponentName = componentName || instanceType;
      const additionalSupportedProps = supportedPropsByComponent[formComponentName];
      return _extends({}, instance, {
        instanceProps: _lodash.default.pickBy(_extends({}, instance.instanceProps, instancePropUpdates), (value, propKey) => {
          return !properties || _lodash.default.includes(definedInstancePropKeys, propKey) || _lodash.default.includes(additionalSupportedProps, propKey);
        })
      });
    });
    collector[key] = (0, _serverTransforms.convertFieldEditorState_toFormJS)(_extends(value, {
      instances: newInstances
    }));
    return collector;
  }, {});
  return formValue;
}