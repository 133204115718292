"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function typesWithID(id) {
  return {
    SET_SELECTED_INDEX: `@@ruby-app/${id}/SET_SELECTED_INDEX`
  };
}
const generators = {
  setSelectedIndex: function (selectedIndex) {
    const {
      TYPES,
      generators: actions
    } = this.getAction();
    const routeParent = this.findAncestorBy(el => el.getRouteElement);
    const selectedTab = this.getChildren()[selectedIndex];
    return dispatch => {
      dispatch({
        type: TYPES.SET_SELECTED_INDEX,
        payload: {
          selectedIndex
        }
      });
      const {
        hashRef
      } = selectedTab.props;
      if (hashRef) {
        const {
          hash = {},
          query
        } = routeParent.getState().routeParams;

        //# call on navigateToPath, allow that action to format the url properly
        dispatch(actions.navigateToPathWithOptions({
          path: window.location.pathname,
          hash: _objectSpread(_objectSpread({}, hash), {}, {
            tab: hashRef
          })
        }));
      }
      dispatch(selectedTab.getAction().generators.onSelected());
    };
  },
  resetStore: function () {
    const {
      TYPES
    } = this.getAction();
    this.clearStatefulCache();
    return {
      type: TYPES.RESET_STORE
    };
  }
};
module.exports = function () {
  return {
    TYPES: typesWithID(this.getID()),
    generators
  };
};