"use strict";

const rubyLogger = require('@rubyapps/ruby-logger');
const packageName = require('../../package.json').name;
const logger = rubyLogger.getLogger(packageName);
module.exports = {
  children: function () {
    if (!this.props.__formattedChildren) {
      logger.warn(`No __formattedChildren for component [${this.getID()}]. This shouldn't happen as we expect this to be provided by ruby-component-builder`);
      return [];
    }
    const {
      default: rubyComponentBuild
    } = require('@rubyapps/ruby-component-builder/src/common/index');
    const idPrefix = this.getID();
    const spec = this.props;
    const theComponentName = spec.key || this.componentName;
    const compoundID = spec.id ? spec.id : idPrefix ? `${idPrefix}.${theComponentName}` : theComponentName;
    const childrenDepth = this.props.__depth + 1;
    const childrenComponents = _.map(this.props.__formattedChildren, (childSpec, index) => {
      const builtChildComponent = rubyComponentBuild(childSpec, `${compoundID}[${index}]`, childrenDepth, index, {
        _parent: this
      });
      return builtChildComponent;
    }).filter(rubyComponentChild => rubyComponentChild != undefined);
    return childrenComponents;
  },
  limitedSelfKeypaths: function () {
    const effectiveSpec = this.props.__originalSpec || this.props;
    if (!effectiveSpec.componentName) {
      console.error(`No formJS spec found for component: [${this.getID()}] props:`, this.props);
      return [];
    }
    return this.limitedKeypathsForNodes([effectiveSpec]);
    //# for complex components, the children wouldn't have props.__originalSpec
    //# (eg. the browser_page_title component under GooglePreview)
    //# fallback to this.props
  },

  limitedChildrenKeypaths: function () {
    const effectiveChildrenSpec = this.props.__formattedChildren;
    if (!effectiveChildrenSpec) {
      console.error(`No __formattedChildren specs found for component: [${this.getID()}] props:`, this.props);
      return [];
    }
    return this.limitedKeypathsForNodes(effectiveChildrenSpec);
  },
  limitedKeypathsForNodes: function (nodes) {
    const {
      contentPropertyHelper
    } = this.getDependencies();
    //# need to pick only children keys  otherwise, the other keys stored here
    //# would override the actual fields
    const fieldSpecs = contentPropertyHelper.specsFromNodes_forTemplateKey(nodes, '');
    //# flatten
    //
    const flattenedFieldSpecs = contentPropertyHelper.nearestKeyedNodesFromSpecs(fieldSpecs);
    const limitedKeypaths = flattenedFieldSpecs.map(n => n.dataPath);
    const limitedParentKeypaths = Object.keys(limitedKeypaths.reduce((collector, keypath) => {
      const parentKeypathArr = keypath.split('.');
      if (parentKeypathArr.length > 1) {
        collector[parentKeypathArr[0]] = parentKeypathArr[0];
      }
      return collector;
    }, {})); //# NOTE: this is uniquely for matter_profile because for some reason
    //# limitKeypaths = ['matter_profile.*.type'...] but excludes 'matter_profile'
    //# whereas normal repeaters only includes the parent key 
    //# Hacky just for matter profiles

    return limitedParentKeypaths.concat(limitedKeypaths);
  }
};