"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.coercedQueryProps = coercedQueryProps;
exports.default = void 0;
var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
const rubyWords = require('@rubyapps/ruby-words');
const Route = require('route-parser');
const queryString = require('query-string');
const path = require('path');
const rubyLogger = require('@rubyapps/ruby-logger');
const packageName = path.basename(__filename.replace(/.*local_modules\//, '').replace(/\//g, ':'), '.js');
const logger = rubyLogger.getLogger(packageName);
const optionsUrl_routeTemplate = '/ruby/api/:version/:model/(:template/):endpointMethod(?*queryString)';
//# made the routeTemplate more permissive to match the following:
//# '/ruby/api/v2/settings/timezones'
//# '/ruby/api/v2/templates/profileOptions?ruby_client_fk=3&templateType=profile_temp'
//# '/ruby/api/v2/content/office/options'

const route = new Route(optionsUrl_routeTemplate);
const queryPropsToConvertToNumber = ['ruby_client_fk', 'content_subsite_fk', 'globaldata_ancestor_fk'];
function coercedQueryProps(queryObject) {
  const coercedQueryObject = _objectSpread({}, queryObject);
  queryPropsToConvertToNumber.forEach(queryPropKey => {
    if (!_lodash.default.isNil(coercedQueryObject[queryPropKey])) {
      try {
        coercedQueryObject[queryPropKey] = JSON.parse(queryObject[queryPropKey]);

        //# NOTE: hacky but if coerced prop is an empty array, we delete it
        //# this is specifically to handle the GDA value, which might
        //# have been resolved as an empty array
        if (_lodash.default.isArray(coercedQueryObject[queryPropKey]) && coercedQueryObject[queryPropKey].length == 0) {
          delete coercedQueryObject[queryPropKey];
        }
      } catch (err) {
        logger.warn(`Cannot parse value for key: [${queryPropKey}];  value: [${coercedQueryObject[queryPropKey]}]`);
      }
    }
  });
  return coercedQueryObject;
}
function queryObjectFromQueryString(theQueryString) {
  const queryObject = queryString.parse(theQueryString);
  Object.keys(queryObject).forEach(queryKey => {
    const queryValue = queryObject[queryKey];
    //# NOTE: queryValue might be [(:globaldata_ancestor_fk)]
    //# which would be an invalid JSON string
    //# TODO: routeTemplate format doesn't work on _.isArray(queryValue)
    const firstChar = _lodash.default.isString(queryValue) ? queryValue.charAt(0) : null;
    if (firstChar == '[' || firstChar == '{') {
      try {
        const parsedQueryValue = JSON.parse(queryValue);
        queryObject[queryKey] = parsedQueryValue;
      } catch (err) {
        //# test that the error is due to a route-parser templated string
        const routeParserTest = new Route(queryValue);
        const routeMatch = routeParserTest.match(queryValue);
        if (Object.keys(routeMatch).length) {
          //# since we're dealing with a routeTemplate
          //# map the queryKey to a special object with the key '_routeTemplate'
          //# and store the original routeTemplate value
          //# along with the params
          queryObject[queryKey] = {
            _routeTemplate: {
              template: queryValue,
              params: routeMatch
            }
          };
        } else {
          logger.warn(`queryValue: [${queryValue}] is an invalid JSON string.`);
        }
      }
    }
  });
  return queryObject;
}
const PLURAL_TO_SINGULAR_MAP = {
  'media': 'media'
};

/**
 * @typedef {Object} OptionsUrlSpec
 * @property {string} version
 * @property {string} model
 * @property {Array} template
 * @property {string} endpointMethod
 * @property {Object} query - query object may include key-value pairs where the value is an array or object
 */

/**
 * @returns {OptionsUrlSpec}
 */
function extractParamsFromOptionsUrl(optionsUrl) {
  const routeMatchObject = route.match(optionsUrl) || {};
  const _queryObject = routeMatchObject.queryString ? queryObjectFromQueryString(routeMatchObject.queryString) : {};
  const queryObject = coercedQueryProps(_queryObject);
  routeMatchObject.query = queryObject;
  routeMatchObject.model = routeMatchObject.model ? PLURAL_TO_SINGULAR_MAP[routeMatchObject.model] || rubyWords.inflection.singularize(routeMatchObject.model) : undefined;
  routeMatchObject.template = routeMatchObject.template ? routeMatchObject.template.split(',') : undefined;
  return _lodash.default.omit(routeMatchObject, ['queryString']);
}
var _default = extractParamsFromOptionsUrl;
exports.default = _default;