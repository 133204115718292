// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".typography__footerCopy___VXBAw {\n}\n\n.typography__link--startUnderlined___Ksy6x {\n    text-decoration: underline;\n}\n.typography__link--startUnderlined___Ksy6x:hover {\n    text-decoration: none;\n}\n", "",{"version":3,"sources":["webpack://./src/local_modules/ruby-styles/src/client/cssModules/typography.cssModule"],"names":[],"mappings":"AAEA;AACA;;AAEA;IACI,0BAA0B;AAC9B;AACA;IACI,qBAAqB;AACzB","sourcesContent":["@value defaultFontSize: 14px;\n\n.footerCopy {\n}\n\n.link--startUnderlined {\n    text-decoration: underline;\n}\n.link--startUnderlined:hover {\n    text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"defaultFontSize": "14px",
	"footerCopy": "typography__footerCopy___VXBAw",
	"link--startUnderlined": "typography__link--startUnderlined___Ksy6x"
};
export default ___CSS_LOADER_EXPORT___;
