"use strict";

var _lodash = _interopRequireDefault(require("lodash"));
var _path = _interopRequireDefault(require("path"));
var _queryString = _interopRequireDefault(require("query-string"));
var _components = _interopRequireDefault(require("@rubyapps/ruby-component-builder/src/common/components"));
var _optionsUrl__extractParams = _interopRequireDefault(require("@rubyapps/ruby-form-js-to-json-schema/src/common/optionsUrl__extractParams.js"));
var _constants = require("@rubyapps/ruby-component-plugin-template-editor/src/common/constants");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); } //# NOTE: this was initialy implemented for searchableFields, we can now genericize it
require('deepdash')(_lodash.default);
const baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
const rubyLogger = require('@rubyapps/ruby-logger');
const packageName = _path.default.basename(__filename.replace(/.*local_modules\//, '').replace(/\//g, ':'), '.js');
const logger = rubyLogger.getLogger(packageName);
const {
  mergedChildrenPropsByKeyFromForm_andDefaultProps
} = require('@rubyapps/ruby-form-js-to-json-schema/src/common/utils');

//# NOTE: `DEFAULT_INCLUDE_ID_PATHS` and `DEFAULT_INCLUDE_KEY_PATHS` are
//# hacks that we are implementing to include fields that would otherwise
//# be excluded (like the `_score` Relevance field defined by the
//# `ruby-model-mixin-relevance` mixin) in the searchableFields spec.
//# Ideally, this mixin should not have to know anything about any particular
//# field
const DEFAULT_INCLUDE_ID_PATHS = ['__score', '__termFreq'];
const DEFAULT_INCLUDE_KEY_PATHS = ['status'];

//# TODO: we handle filterTags.include in two different ways:
//# calling on 'reshapedSpecsUsingIdPaths' at the <Form> component 
//# So this only works for the form component or <TokenTagger/> when we're expanding it with the nested fields
//# We currently also support inclusion of localFields but it's not the ideal way to do so since 
//# the config may create duplicate fields (since we don't remove the field from the origin)
//# We want to update so the two sets of logic is shared
//# Currently, since this is async, there's a limitation where it only works for referencing 
//# callingModule - so we can check if we should include namespace fields
const fieldsSpecsFromTemplate = (selfModule, templateData, callingModule) => {
  const idPathsFromListerConfig = _lodash.default.get(templateData, 'form.listerConfig.expandedSearch.filterTags.include') || [];
  const wantedIdPaths = _lodash.default.isEmpty(idPathsFromListerConfig) ? ['*'] //# Wildcard means include everything
  : _lodash.default.uniq(idPathsFromListerConfig.concat(DEFAULT_INCLUDE_ID_PATHS));
  const specsFromTemplateData = specsFromTemplateData_usingCtx(selfModule, templateData);
  return reshapedSpecsUsingIdPaths(specsFromTemplateData, wantedIdPaths);
};
module.exports = {
  fieldsSpecsFromTemplate,
  specsFromNodes_andCtx
};
function reshapedSpecsUsingIdPaths(specs) {
  let idPaths = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  const reducedSpecs = !_lodash.default.isEmpty(idPaths) ? _lodash.default.flatMap(idPaths, idPath => {
    return reduceSpecsUsingIdPath_andCtx(specs, idPath);
  }) : specs;
  return prunedTreeUsingIdPaths(reducedSpecs, idPathsToPruneFromIdPaths(idPaths));
}
function reduceSpecsUsingIdPath_andCtx(specs, idPath) {
  let ctx = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return specs.reduce((acc, spec) => {
    const specsMatchingIdPath = specsMatchingIdPath_usingCtx(spec, idPath, ctx);
    return acc.concat(specsMatchingIdPath);
  }, []);
}
function specsMatchingIdPath_usingCtx(node, wantedIdPath) {
  let ctx = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  const {
    id,
    children = []
  } = node;
  const {
    idPathArray = [],
    matchedIds = []
  } = ctx;
  const wantedIdPathArray = wantedIdPath.split('.');
  const wantedIdPathIsWildcard = wantedIdPathArray[wantedIdPathArray.length - 1] === '*';
  const currentNodeIdPathArray = idPathArray.concat(id ? id : '*');
  const currentNodeIdPath = currentNodeIdPathArray.join('.');
  const parentPathOfWantedIdPath = _lodash.default.initial(wantedIdPathArray).join('.');
  const parentPathOfCurrentNodeIdPath = _lodash.default.initial(currentNodeIdPathArray).join('.');
  const currentNodeMatchesWantedIdPath = wantedIdPath === currentNodeIdPath || wantedIdPathIsWildcard && parentPathOfWantedIdPath === parentPathOfCurrentNodeIdPath;
  const descendantNodesMatchingWantedIdPath = !_lodash.default.isEmpty(children) ? reduceSpecsUsingIdPath_andCtx(children, wantedIdPath, _extends({}, ctx, {
    idPathArray: currentNodeIdPathArray
  })) : [];
  const nodesMatchingWantedIdPath = currentNodeMatchesWantedIdPath ? [].concat(node) : descendantNodesMatchingWantedIdPath;
  return _lodash.default.flatMapDeep(nodesMatchingWantedIdPath, _lodash.default.identity);
}
function prunedTreeUsingIdPaths(tree) {
  let idPaths = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  const prunedTree = idPaths.reduce((acc, idPath) => {
    return pruneTreeUsingIdPath_andCtx(acc, idPath);
  }, tree);
  return prunedTree;
}
function pruneTreeUsingIdPath_andCtx(tree, idPath) {
  let ctx = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  const {
    idPathArray = []
  } = ctx;
  const idPathToParentNode = _lodash.default.initial(idPath.split('.')).join('.');
  const pruneId = _lodash.default.last(idPath.split('.'));
  const prunedTree = tree.reduce((acc, treeNode) => {
    const {
      id,
      children = []
    } = treeNode;
    const currentIdPathArray = idPathArray.concat(id ? id : '*');
    const currentIdPath = currentIdPathArray.join('.');
    if (currentIdPath === idPathToParentNode) {
      return acc.concat(_extends({}, treeNode, {
        children: children.filter(child => !child.id || child.id !== pruneId)
      }));
    }
    return _lodash.default.isEmpty(children) ? acc.concat(treeNode) : acc.concat(_extends({}, treeNode, {
      children: pruneTreeUsingIdPath_andCtx(children, idPath, _extends({}, ctx, {
        idPathArray: currentIdPathArray
      }))
    }));
  }, []);
  return prunedTree;
}
function idPathsToPruneFromIdPaths() {
  let idPaths = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return idPaths.reduce((acc, currentIdPath) => {
    const isDescendantPath = idPaths.filter(idPath => idPath !== currentIdPath).find(idPath => {
      const currentIdPathPartials = currentIdPath.split('.');
      const idPathPartials = idPath.split('.').filter(partial => partial !== '*');
      return currentIdPathPartials.reduce((acc, partial, idx) => {
        return !!acc ? acc : currentIdPathPartials.length > idPathPartials.length && currentIdPathPartials[idx] === idPathPartials[idx];
      }, false);
    });
    return isDescendantPath ? acc.concat(currentIdPath) : acc;
  }, []);
}

/**
 *  @property ctx.callingModule
 *  @property ctx.breadcrumbArray
 *  @property ctx.templateKey
 *  @property ctx.dataPathArray
 *  @property ctx.nestingOf - 
 *  @property ctx.selfModule - 
 *
 **/
function specsFromTemplateData_usingCtx(selfModule, templateData) {
  let ctx = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  let options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {
    nestedSearch: true
  };
  if (!templateData) {
    logger.error(`[specsFromTemplateData_usingCtx] templateData is undefined for template: [${ctx.templateKey}]`);
    return [];
  }
  const {
    form = {},
    key: templateKey
  } = templateData;
  const {
    children = []
  } = form;
  const ctxForTemplate = _extends({}, ctx, {
    breadcrumbArray: [],
    templateKey,
    dataPathArray: []
  });
  return specsFromNodes_andCtx(selfModule, children, ctxForTemplate, options);
}

//# NOTE: not great, but some getDefaultProps() accesses `this`
const polyfilledComponentInstance_withProps = (component, props) => ({
  getID: () => {
    props.id || component.componentName;
  }
});
function _nodeWithMergedDefaultProps(node) {
  const {
    componentName
  } = node;
  const component = _components.default[componentName];
  const polyfilledComponentInstance = polyfilledComponentInstance_withProps(component, node);
  const component__defaultProps = component.getDefaultProps ? component.getDefaultProps.call(polyfilledComponentInstance, node) : {};
  const nodeWithMergedDefaultProps = _extends({}, component__defaultProps, node, node.hasOwnProperty('childrenPropsByKey') ? {
    childrenPropsByKey: mergedChildrenPropsByKeyFromForm_andDefaultProps(node, component__defaultProps)
  } : {}); //# This is the node representing a field

  return nodeWithMergedDefaultProps;
}
function specsFromNodes_andCtx(selfModule) {
  let nodes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  let ctx = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  let options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {
    nestedSearch: true
  };
  const searchableFieldsSpec = nodes.reduce((acc, node) => {
    const {
      componentName,
      __alreadyFormattedByContentPropertyHelper__
    } = node;
    const {
      dataPathArray = []
    } = ctx;

    //# Previously, we relied on differentiating between children and _internal_children
    //# however, that doesn't work because some components like NamespaceSelector
    //# will lift up children fields as siblings
    //# so we need to mark them as having been processed
    if (__alreadyFormattedByContentPropertyHelper__) {
      acc.push(node);
      return acc;
    }
    const component = _components.default[componentName];
    if (!component) {
      componentName && logger.warn(`[specsFromNodes_andCtx()] No component found for ${componentName}] with formJS valued:`, node);
      //# Only need to log this out if component isn't found even if componentName is available
      //# there are other cases (eg. LinkUrl) where it's children do not have componentNames defined because it's a managed component
      //# so we can ignore them for now
      //# if we need them to be searchable, we should update the complex components source and add the componentName, I think profileIdentity or internationAddress already does it
      return acc;
    }
    const nodeWithMergedDefaultProps = _nodeWithMergedDefaultProps(node);
    //const nodeWithMergedDefaultProps = mergedChildrenPropsByKeyFromForm_andDefaultProps(
    //

    const {
      url,
      listerConfig = {}
    } = nodeWithMergedDefaultProps;
    const nestedSearch = url && (_lodash.default.get(listerConfig, 'expandedSearch.filterTags') || _lodash.default.get(listerConfig, 'expandedSearch') === true) && options.nestedSearch;

    //# Need to update dataPathArray with current key
    //# the call to fieldSpecsForNode needs it so individual getFieldSpecFromProps can 
    //# have that info
    const parentDataPathArray = dataPathArray;
    //# NOTE: dataPathArray is the parent dataPathArray at this point
    //# the dataPath for nodeWithMergedDefaultProps will be determined in `specFromNode_andCtx` 
    const ctxWithSelfModule = _objectSpread(_objectSpread({}, ctx), {}, {
      selfModule,
      dataPathArray: parentDataPathArray
    });
    const fieldSpecs = fieldSpecsForNode(nodeWithMergedDefaultProps, ctxWithSelfModule);
    //# a field might map to multiple fieldSpec (eg. international address maps to multiple simple fields)

    //# NOTE: We are relying on a match between the `key` and `componentName`
    //# properties to find the field spec from `fieldSpecs` that matches the
    //# current `node`. The assumption being made here is that it would never
    //# be the case that a given node would have field specs where multiple
    //# items have the same key.
    const matchingFieldSpecForNode = fieldSpecs.find(_lodash.default.matches({
      key: nodeWithMergedDefaultProps.key,
      componentName: nodeWithMergedDefaultProps.componentName
    })) || {};
    //# We look for the fieldSpec out of all fieldSpecs returned to find the one representing the node
    //# NOTE: that typically fieldSpecsForNode() returns one fieldSpec

    const nodeWithMergedFieldSpec = _extends({}, nodeWithMergedDefaultProps //# merge in default props first
    , matchingFieldSpecForNode ? matchingFieldSpecForNode : {}
    //, node //# need to finally include node because node includes custom overrides for the particular node whereas nodeWithMergedDefaultProps and matchingFieldSpecForNode
    );

    const flattenedFieldSpecs = _lodash.default.flatMap(fieldSpecs, fieldSpec => {
      return _lodash.default.isEqual(matchingFieldSpecForNode, fieldSpec) ? specFromNode_andCtx(selfModule, nodeWithMergedFieldSpec, ctxWithSelfModule, options) : specFromNode_andCtx(selfModule, _extends({}, fieldSpec, {
        parentSearchableFieldSpec: nodeWithMergedFieldSpec
      }), ctxWithSelfModule, options);
    });
    const retval = selectNodeForSpec(nodeWithMergedFieldSpec, ctxWithSelfModule) ?
    //# if include children, we recurse
    acc.concat(_lodash.default.isEmpty(flattenedFieldSpecs) ? specFromNode_andCtx(selfModule, nodeWithMergedFieldSpec, ctxWithSelfModule, options) : [], flattenedFieldSpecs, nestedSearch ? nestedSpecsFromNode_andCtx(selfModule, nodeWithMergedFieldSpec, ctxWithSelfModule, options) : []) : acc;
    return retval;
  }, []);
  return searchableFieldsSpec.map(searchableFieldSpec => specWithHydratedListerConfig(searchableFieldSpec));
}

//# determing if we need to include children
function selectNodeForSpec(node, ctx) {
  const {
    children = [],
    componentName,
    key,
    id
  } = node;
  //# find component and see if it has childrenPropsByKey

  const nodeWithMergedDefaultProps = _nodeWithMergedDefaultProps(node);
  return DEFAULT_INCLUDE_ID_PATHS.includes(id) || DEFAULT_INCLUDE_KEY_PATHS.includes(key) || key || !_lodash.default.isEmpty(fieldSpecsForNode(node, ctx)) || children.find(childNode => selectNodeForSpec(childNode, ctx)) || nodeWithMergedDefaultProps.hasOwnProperty('childrenPropsByKey') && _lodash.default.size(nodeWithMergedDefaultProps.childrenPropsByKey);
}

/**
 *  Returns the final format of the fieldSpec (from fieldSpecsForNode() call), because we need 
 *  to recurse into the children
 **/
function specFromNode_andCtx(selfModule, node) {
  let ctx = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  let options = arguments.length > 3 ? arguments[3] : undefined;
  const {
    children,
    componentName,
    dataPath,
    label,
    url,
    key,
    listerConfig = {}
  } = node;
  const filterTags__includes = _lodash.default.get(listerConfig, 'expandedSearch.filterTags.include');
  //# NOTE: localSearch handles filterTag includes of local fields

  const {
    dataPathArray = [],
    templateKey,
    nestingOf,
    nestingLabelArray = [],
    parentTemplateKey
  } = ctx;

  //# NOTE: currently it doesn't seem like this is an issue so we can ignore it for now
  //# TODO check ctx.callingModule and determine whether we include namespace or not
  /*
  if (componentName === 'NamespaceSelector') {
      //# TODO: update so we don't need this check, instead, we can rely on specsFromNodes_andCtx calling on 
      //# TODO: check context, if we want to include fieldSpecsForNode
      const retval = specsFromNodes_andCtx(selfModule, children, ctx);
       debugger;
      return retval;
  }
  */

  const formsState = formsStateFromSelfModule(selfModule);
  const templateData = formsState[templateKey] || {}; //# NOTE tempalteKey could be empty

  const {
    name: templateName
  } = templateData;
  const nestingLabel = nestingOf && nestingLabelArray.join(' › ');
  const displayLabel = _lodash.default.get(listerConfig, 'expandedSearch.filterTags.labelAs') || label;
  const labelForSpec = componentName === 'Tab' || componentName === 'Header' ? _lodash.default.compact([nestingLabel || templateName, displayLabel]).join(' › ') : displayLabel;
  let childrenNodes = children;
  if (filterTags__includes && !url) {
    //# only allow this if the field is a container field like fieldset
    //# Get nodes based on include ids

    const wantedIdPaths = filterTags__includes;
    childrenNodes = wantedIdPaths.reduce((collector, idPath) => {
      const foundNodes = nodesInTemplate_atIdPath(templateData, idPath);
      return collector.concat(foundNodes);
    }, []);
  }

  //# if node.dataPath is available, then we use that for the dataPathArray
  //# otherwise, we concat key to the dataPathArray (which is the parent dataPathArray)
  const dataPathArrayForChildren = dataPath ? dataPath.split('.') : dataPathArray.concat(key ? key : []);
  const hydratedChildren = [].concat(childrenNodes && childrenNodes.length ? specsFromNodes_andCtx(selfModule, childrenNodes, _objectSpread(_objectSpread({}, ctx), {}, {
    dataPathArray: dataPathArrayForChildren
  }), options) : []);
  return [].concat(componentName === 'Repeater' && url ? specFromProfileRepeaterNode_andCtx(selfModule, node, ctx) : _extends({}, node, {
    dataPath: node.dataPath || dataPathArrayForChildren.join('.'),
    breadcrumbPartial: labelForSpec,
    label: labelForSpec,
    foundIn__templateKey: templateKey,
    nestingOf,
    parentTemplateKey,
    parentDataPath: dataPathArray.join('.'),
    __alreadyFormattedByContentPropertyHelper__: true
  }, hydratedChildren.length ? {
    children: hydratedChildren
  } : {}));
}
function specWithHydratedListerConfig(searchableFieldSpec) {
  const {
    //# NOTE: `excludeFromAdvancedLister`, `excludeFromColumnSelection`
    //# and `excludeFromFilterSelection` are being deprecated as top level
    //# keys. We use them here to maintain backwards compatibility. We are
    //# now relying on `excludeFromFilterSelection` and `excludeFromColumnSelection`
    //# existing within `listerConfig` object, and `excludeFromAdvancedLister`
    //# is being deprecated altogether
    excludeFromAdvancedLister = false,
    excludeFromColumnSelection = false,
    excludeFromFilterSelection = false,
    listerConfig = {
      excludeFromColumnSelection: false,
      excludeFromFilterSelection: false
    },
    parentSearchableFieldSpec = {}
  } = searchableFieldSpec;
  const hydratedListerConfig = _extends({}, listerConfig, {
    excludeFromColumnSelection: !!(excludeFromAdvancedLister || listerConfig.excludeFromColumnSelection || excludeFromColumnSelection || _lodash.default.get(parentSearchableFieldSpec, 'listerConfig.excludeFromColumnSelection')),
    excludeFromFilterSelection: !!(excludeFromAdvancedLister || listerConfig.excludeFromFilterSelection || excludeFromFilterSelection || _lodash.default.get(parentSearchableFieldSpec, 'listerConfig.excludeFromFilterSelection'))
  });
  return _extends({}, _lodash.default.omit(searchableFieldSpec, ['excludeFromColumnSelection', 'excludeFromFilterSelection', 'excludeFromAdvancedLister']), {
    listerConfig: hydratedListerConfig
  });
}
function specFromProfileRepeaterNode_andCtx(selfModule, profileRepeaterNode) {
  let ctx = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  let options = arguments.length > 3 ? arguments[3] : undefined;
  const formsState = formsStateFromSelfModule(selfModule);
  const {
    dataPathArray = [],
    nestingOf,
    parentTemplateKey,
    templateKey
  } = ctx;
  const {
    url,
    key: repeaterKey,
    label
  } = profileRepeaterNode;
  const urlParamString = url.substring(url.indexOf('?'));
  const urlParams = _queryString.default.parse(urlParamString);
  const {
    templateType: targetTemplateType = _constants.PROFILE_TEMPLATE
  } = urlParams;
  const profileTemplateSpecs = _lodash.default.chain(formsState).filter((template, templateKey) => template.templateType === targetTemplateType).sortBy('name').flatMap(template => {
    const {
      form = {},
      key: templateKey
    } = template;
    const {
      children: formChildren = []
    } = form;
    return _lodash.default.chain(formChildren).filter(node => selectNodeForSpec(node, _objectSpread(_objectSpread({}, ctx), {}, {
      selfModule
    }))).map(node => {
      const {
        children = [],
        key
      } = node;
      const parentDataPath = dataPathArray.join('.');
      const updatedDataPathArray = dataPathArray.concat(repeaterKey, '*', key);
      return _extends({}, node, {
        dataPath: updatedDataPathArray.join('.'),
        breadcrumbPartial: template.name,
        label: `Profile: ${template.name}`,
        children: specsFromNodes_andCtx(selfModule, children, _extends({}, ctx, {
          dataPathArray: updatedDataPathArray
        }), options),
        foundIn__templateKey: templateKey,
        __alreadyFormattedByContentPropertyHelper__: true,
        nestingOf,
        parentTemplateKey,
        parentDataPath
      });
    }).value();
  }).value();
  const profileRepeaterTypeSpec = _extends({}, profileRepeaterNode, {
    componentName: 'Hidden',
    breadcrumbPartial: `${label} Type`,
    key: 'type',
    dataPath: dataPathArray.concat([repeaterKey, '*', 'type']).join('.'),
    data_type: ['string'],
    label: `${label} Type`,
    listerConfig: {
      excludeFromFilterSelection: false
    },
    foundIn__templateKey: templateKey,
    __alreadyFormattedByContentPropertyHelper__: true,
    parentTemplateKey,
    nestingOf
  });

  //# NOTE: For Matter Profile Repeaters - we explicitly inject a
  //# searchable field spec for the profile type. The reason we do
  //# this specifically for Matter Profile Repeaters is because
  //# they don't have inline module options (and thefore currently,
  //# the Repeater component's getFieldSpecForProps method will not
  //# expose the repeater module type spec)
  return [].concat(profileRepeaterTypeSpec, profileTemplateSpecs);
}
function nestedSpecsFromNode_andCtx(selfModule, node) {
  let ctx = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  let options = arguments.length > 3 ? arguments[3] : undefined;
  const {
    url,
    listerConfig = {},
    dataPath,
    label
  } = node;
  const {
    dataPathArray = [],
    nestingLabelArray = []
  } = ctx;
  const formsState = formsStateFromSelfModule(selfModule);
  const modelInfo = url && (0, _optionsUrl__extractParams.default)(url);
  const templateKeys = modelInfo ? _lodash.default.castArray(modelInfo.template) : [];
  const wantedIdPaths = _lodash.default.get(listerConfig, 'expandedSearch.filterTags.include') || [];
  const nestedSpecs = _lodash.default.flatMap(templateKeys, templateKey => {
    const templateData = formsState[templateKey];
    return specsFromTemplateData_usingCtx(selfModule, templateData, {
      dataPathArray: dataPathArray.concat(dataPath),
      nestingOf: dataPath,
      nestingLabelArray: nestingLabelArray.concat(label),
      parentTemplateKey: ctx.templateKey,
      templateKey //# NOTE: used for debugging since templateData might be undefined
    }, options);
  });
  return reshapedSpecsUsingIdPaths(nestedSpecs, wantedIdPaths);
}
function nodesInTemplate_atIdPath(template, idPath) {
  const idPath_arr = idPath.split('.');
  const foundNodes = idPath_arr.reduce((collector, idPath_partial) => {
    //# iteratie collector and search for the closest decendant with matching idPath_partial
    return collector.reduce((filteredNodes, node) => {
      const foundNode = _lodash.default.findDeep(node, (value, key, parentValue, context) => {
        return value.id == idPath_partial;
      }, {
        checkCircular: false,
        pathFormat: 'string',
        includeRoot: false,
        childrenPath: 'children'
      });
      if (foundNode) {
        filteredNodes.push(foundNode.value);
      }
      return filteredNodes;
    }, []);
  }, [template.form]);
  return foundNodes;
}

/**
 * @param {Object} ctx.selfModule - the caller's RubyComponent
 */
function fieldSpecsForNode(node, ctx) {
  const {
    componentName
  } = node;
  const component = _components.default[componentName];
  if (!component) {
    return [];
  }
  let getFieldSpecFromProps = component.getFieldSpecFromProps
  //# find the first getFieldSpecFromProps based on the component mixin
  || [...component.mixins].reverse().reduce((collector, mixin) => {
    if (collector) {
      return collector;
    }
    return mixin.getFieldSpecFromProps;
  }, undefined) || baseFieldMixin.getFieldSpecFromProps;
  getFieldSpecFromProps = getFieldSpecFromProps.bind(component);
  return getFieldSpecFromProps(node, ctx);
}
function formsStateFromSelfModule(selfModule) {
  const rootModule = selfModule.getRoot();
  const formsID = selfModule.props.formsID || 'rubyComponentForms';
  const formsComponent = rootModule.findDescendentByID(formsID);
  const formsState = formsComponent.getState();
  return formsState;
}