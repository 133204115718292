"use strict";

var _lodash = _interopRequireDefault(require("lodash"));
var _components = _interopRequireDefault(require("../../../ruby-component-builder/src/common/components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
function fieldSpecsTree_fromFormChildren(formChildren, ctx) {
  const fieldSpecsTree = selectableNodesFromFormChildren(formChildren).reduce((acc, node) => {
    const {
      componentName,
      children = []
    } = node;
    if (componentName === 'NamespaceSelector') {
      return acc.concat(fieldSpecsTree_fromFormChildren(children, ctx));
    }
    const Component = _components.default[componentName];
    const nodeWithMergedDefaultProps = _extends({}, Component && Component.getDefaultProps ? Component.getDefaultProps(node) : {}, node);
    const fieldSpecs = fieldSpecsForNode(nodeWithMergedDefaultProps, ctx);
    //# NOTE: We are relying on a match between the `key` and `componentName`
    //# properties to find the field spec from `fieldSpecs` that matches the
    //# current `node`. The assumption being made here is that it would never
    //# be the case that a given node would have field specs where multiple
    //# items have the same key.
    const matchingFieldSpecForNode = fieldSpecs.find(_lodash.default.matches({
      key: nodeWithMergedDefaultProps.key,
      componentName: nodeWithMergedDefaultProps.componentName
    })) || {};
    const nodeWithMergedFieldSpec = _extends({}, nodeWithMergedDefaultProps, matchingFieldSpecForNode ? _extends({}, matchingFieldSpecForNode
    //# NOTE: For components like the `ProfileIdentity` widget that have `childrenPropsByKey`
    //# , we are considering each property of `childrenPropsByKey` as a child of the component
    , matchingFieldSpecForNode.hasOwnProperty('childrenPropsByKey') ? {
      children: [].concat(children, Object.keys(matchingFieldSpecForNode.childrenPropsByKey).map(childPropKey => fieldSpecs.find(spec => spec.key === childPropKey)).filter(_lodash.default.identity))
    } : {}) : {});
    return acc.concat(_extends({}, nodeWithMergedFieldSpec, !_lodash.default.isEmpty(children) ? {
      children: fieldSpecsTree_fromFormChildren(children, ctx)
    } : {}));
  }, []);
  return fieldSpecsTree;
}
function selectableNodesFromFormChildren() {
  let formChildren = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  const selectableNodes = _lodash.default.reduce(formChildren, (acc, node) => {
    const {
      children = []
    } = node;
    return nodePicker(node) ? acc.concat(_extends({}, node, {
      children: selectableNodesFromFormChildren(children)
    })) : acc;
  }, []);
  return selectableNodes;
}
function nodePicker(node) {
  const {
    children = [],
    key,
    componentName = ""
  } = node;
  const fieldPickerPropsFromNode = _lodash.default.get(node, 'forComponent.FieldsPicker') || {};
  const {
    exclude = false
  } = fieldPickerPropsFromNode;
  const shouldSelect = exclude ? false : !_lodash.default.isEmpty(children) ? children.find(nodePicker) : componentName !== 'Hidden' && componentName !== 'Info' && key && _lodash.default.isEmpty(children) && !exclude;
  return shouldSelect;
}
function fieldSpecsForNode(node, ctx) {
  const {
    componentName
  } = node;
  const component = _components.default[componentName];
  ctx.dataPathArray = ctx.dataPathArray || [];
  if (!component) {
    return [];
  }
  let getFieldSpecFromProps = component.getFieldSpecFromProps
  //# find the first getFieldSpecFromProps based on the component mixin
  || [...component.mixins].reverse().reduce((collector, mixin) => {
    if (collector) {
      return collector;
    }
    return mixin.getFieldSpecFromProps;
  }, undefined) || baseFieldMixin.getFieldSpecFromProps;
  getFieldSpecFromProps = getFieldSpecFromProps.bind(component);
  return getFieldSpecFromProps(node, ctx);
}
module.exports = fieldSpecsTree_fromFormChildren;