"use strict";

//import stylesheetUrl from "file-loader!extract-loader!css-loader!./css/content.css";
const contentStyle = require("./css/content.css"); //# NOTE 20210806 - for some reason extract-loader isn't playing nice in webpack5.
//# read more about it in our webpack config
//# for now, it's expected that you get inline content back and you need to use content_style

const Promise = require('bluebird');
const _ = require('lodash');
const CONSTANTS = require('../../common/constants');
const {
  COMPONENT_NAME
} = CONSTANTS;
module.exports = {
  pluginName: COMPONENT_NAME
};
/**
 * $Id: editor_plugin_src.js 425 2007-11-21 15:17:39Z spocke $
 *
 * @author Moxiecode
 * @copyright Copyright © 2004-2008, Moxiecode Systems AB, All rights reserved.
 */
const COMMAND_STRING = 'mceSpellCheck';
const DEFAULT_spellchecker_api_url = '/ruby/api/spellcheck';
const DEFAULT_spellchecker_languages = '+English=en'; //',Danish=da,Dutch=nl,Finnish=fi,French=fr,German=de,Italian=it,Polish=pl,Portuguese=pt,Spanish=es,Swedish=sv';
const DEFAULT_spellchecker_enabledByDefault = true;
(function () {
  var JSONRequest = tinymce.util.JSONRequest,
    each = tinymce.each,
    DOM = tinymce.DOM;
  var DomTextMatcher = function (node, editor) {
    var m,
      matches = [],
      skipMatches = [],
      text,
      dom = editor.dom;
    var blockElementsMap, hiddenTextElementsMap, shortEndedElementsMap;
    blockElementsMap = editor.schema.getBlockElements(); // H1-H6, P, TD etc
    hiddenTextElementsMap = editor.schema.getWhiteSpaceElements(); // TEXTAREA, PRE, STYLE, SCRIPT
    shortEndedElementsMap = editor.schema.getShortEndedElements(); // BR, IMG, INPUT

    // Some Custom Elements added here -- we always want sup and sub elements to have
    // spaces after them
    blockElementsMap['SUP'] = {};
    blockElementsMap['SUB'] = {};
    blockElementsMap['sup'] = {};
    blockElementsMap['sub'] = {};
    function createMatch(m, data) {
      if (!m[0]) {
        throw 'findAndReplaceDOMText cannot handle zero-length matches';
      }
      return {
        start: m.index,
        end: m.index + m[0].length,
        text: m[0],
        data: data
      };
    }
    function getText(node) {
      var txt;
      if (node.nodeType === 3) {
        return node.data;
      }
      if (hiddenTextElementsMap[node.nodeName] && !blockElementsMap[node.nodeName]) {
        return '';
      }
      txt = '';
      if (blockElementsMap[node.nodeName] || shortEndedElementsMap[node.nodeName]) {
        txt += '\n';
      }
      if (node = node.firstChild) {
        do {
          txt += getText(node);
        } while (node = node.nextSibling);
      }
      return txt;
    }
    function stepThroughMatches(node, matches, replaceFn) {
      var startNode,
        endNode,
        startNodeIndex,
        endNodeIndex,
        innerNodes = [],
        atIndex = 0,
        curNode = node,
        matchLocation,
        matchIndex = 0;
      matches = matches.slice(0);
      matches.sort(function (a, b) {
        return a.start - b.start;
      });
      matchLocation = matches.shift();
      out: while (true) {
        if (blockElementsMap[curNode.nodeName] || shortEndedElementsMap[curNode.nodeName]) {
          atIndex++;
        }
        if (curNode.nodeType === 3) {
          if (!endNode && curNode.length + atIndex >= matchLocation.end) {
            // We've found the ending
            endNode = curNode;
            endNodeIndex = matchLocation.end - atIndex;
          } else if (startNode) {
            // Intersecting node
            innerNodes.push(curNode);
          }
          if (!startNode && curNode.length + atIndex > matchLocation.start) {
            // We've found the match start
            startNode = curNode;
            startNodeIndex = matchLocation.start - atIndex;
          }
          atIndex += curNode.length;
        }
        if (startNode && endNode) {
          curNode = replaceFn({
            startNode: startNode,
            startNodeIndex: startNodeIndex,
            endNode: endNode,
            endNodeIndex: endNodeIndex,
            innerNodes: innerNodes,
            match: matchLocation.text,
            matchIndex: matchIndex
          });

          // replaceFn has to return the node that replaced the endNode
          // and then we step back so we can continue from the end of the
          // match:
          atIndex -= endNode.length - endNodeIndex;
          startNode = null;
          endNode = null;
          innerNodes = [];
          matchLocation = matches.shift();
          matchIndex++;
          if (!matchLocation) {
            break; // no more matches
          }
        } else if ((!hiddenTextElementsMap[curNode.nodeName] || blockElementsMap[curNode.nodeName]) && curNode.firstChild) {
          // Move down
          curNode = curNode.firstChild;
          continue;
        } else if (curNode.nextSibling) {
          // Move forward:
          curNode = curNode.nextSibling;
          continue;
        }

        // Move forward or up:
        while (true) {
          if (curNode.nextSibling) {
            curNode = curNode.nextSibling;
            break;
          } else if (curNode.parentNode !== node) {
            curNode = curNode.parentNode;
          } else {
            break out;
          }
        }
      }
    }

    /**
     * Generates the actual replaceFn which splits up text nodes
     * and inserts the replacement element.
     */
    function genReplacer(callback) {
      function makeReplacementNode(fill, matchIndex) {
        var match = matches[matchIndex];
        if (!match.stencil) {
          match.stencil = callback(match);
        }
        var clone = match.stencil.cloneNode(false);
        clone.setAttribute('data-mce-index', matchIndex);
        if (fill) {
          clone.appendChild(dom.doc.createTextNode(fill));
        }
        return clone;
      }
      return function replace(range) {
        var before,
          after,
          parentNode,
          startNode = range.startNode,
          endNode = range.endNode,
          matchIndex = range.matchIndex,
          doc = dom.doc;
        if (startNode === endNode) {
          var node = startNode;
          parentNode = node.parentNode;
          if (range.startNodeIndex > 0) {
            // Add "before" text node (before the match)
            before = doc.createTextNode(node.data.substring(0, range.startNodeIndex));
            parentNode.insertBefore(before, node);
          }

          // Create the replacement node:
          var el = makeReplacementNode(range.match, matchIndex);
          parentNode.insertBefore(el, node);
          if (range.endNodeIndex < node.length) {
            // Add "after" text node (after the match)
            after = doc.createTextNode(node.data.substring(range.endNodeIndex));
            parentNode.insertBefore(after, node);
          }
          node.parentNode.removeChild(node);
          return el;
        } else {
          // Replace startNode -> [innerNodes...] -> endNode (in that order)
          before = doc.createTextNode(startNode.data.substring(0, range.startNodeIndex));
          after = doc.createTextNode(endNode.data.substring(range.endNodeIndex));
          var elA = makeReplacementNode(startNode.data.substring(range.startNodeIndex), matchIndex);
          var innerEls = [];
          for (var i = 0, l = range.innerNodes.length; i < l; ++i) {
            var innerNode = range.innerNodes[i];
            var innerEl = makeReplacementNode(innerNode.data, matchIndex);
            innerNode.parentNode.replaceChild(innerEl, innerNode);
            innerEls.push(innerEl);
          }
          var elB = makeReplacementNode(endNode.data.substring(0, range.endNodeIndex), matchIndex);
          parentNode = startNode.parentNode;
          parentNode.insertBefore(before, startNode);
          parentNode.insertBefore(elA, startNode);
          parentNode.removeChild(startNode);
          parentNode = endNode.parentNode;
          parentNode.insertBefore(elB, endNode);
          parentNode.insertBefore(after, endNode);
          parentNode.removeChild(endNode);
          return elB;
        }
      };
    }
    function unwrapElement(element) {
      var parentNode = element.parentNode;
      parentNode.insertBefore(element.firstChild, element);
      element.parentNode.removeChild(element);
    }
    function getWrappersByIndex(index) {
      var elements = node.getElementsByTagName('*'),
        wrappers = [];
      index = typeof index == "number" ? "" + index : null;
      for (var i = 0; i < elements.length; i++) {
        var element = elements[i],
          dataIndex = element.getAttribute('data-mce-index');
        if (dataIndex !== null && dataIndex.length) {
          if (dataIndex === index || index === null) {
            wrappers.push(element);
          }
        }
      }
      return wrappers;
    }

    /**
     * Returns the index of a specific match object or -1 if it isn't found.
     *
     * @param  {Match} match Text match object.
     * @return {Number} Index of match or -1 if it isn't found.
     */
    function indexOf(match) {
      var i = matches.length;
      while (i--) {
        if (matches[i] === match) {
          return i;
        }
      }
      return -1;
    }

    /**
     * Filters the matches. If the callback returns true it stays if not it gets removed.
     *
     * @param {Function} callback Callback to execute for each match.
     * @return {DomTextMatcher} Current DomTextMatcher instance.
     */
    function filter(callback) {
      var filteredMatches = [];
      each(function (match, i) {
        if (callback(match, i)) {
          filteredMatches.push(match);
        }
      });
      matches = filteredMatches;

      /*jshint validthis:true*/
      return this;
    }

    /**
     * Executes the specified callback for each match.
     *
     * @param {Function} callback  Callback to execute for each match.
     * @return {DomTextMatcher} Current DomTextMatcher instance.
     */
    function each(callback) {
      for (var i = 0, l = matches.length; i < l; i++) {
        if (callback(matches[i], i) === false) {
          break;
        }
      }

      /*jshint validthis:true*/
      return this;
    }

    /**
     * Wraps the current matches with nodes created by the specified callback.
     * Multiple clones of these matches might occur on matches that are on multiple nodex.
     *
     * @param {Function} callback Callback to execute in order to create elements for matches.
     * @return {DomTextMatcher} Current DomTextMatcher instance.
     */
    function wrap(callback) {
      if (matches.length) {
        stepThroughMatches(node, matches, genReplacer(callback));
      }

      /*jshint validthis:true*/
      return this;
    }

    /**
     * Finds the specified patterns and adds them to the SKIP-matches collection.
     *
     * @param {RegExp} delimRegex Global regexp to split the text by
     * @param {Array} patterns Array of regex to search each piece of split text by
     * @return {DomTextMatcher} Current DomTextMatcher instance.
     */
    function skip(delimRegex, patterns) {
      // Loop until no more text
      var prev = 0;
      while (prev != null) {
        // Find the next space in text (or whatever delimRegex is)
        var delim = delimRegex.exec(text);

        // Figure out the word right before the space
        var end = delim ? delim.index : text.length;
        var word = text.substring(prev, end);

        // If the word right before the space matches one of the patterns
        for (var i = 0; i < patterns.length; i++) {
          var pattern = patterns[i];
          if (pattern.test(word)) {
            // Then make a note to skip it
            skipMatches.push({
              start: prev,
              end: end
            });
            break;
          }
        }

        // Update the prev index in text
        if (delim) prev = delim.index + delim[0].length;else prev = null;
      } // while

      return this;
    } // skip

    /**
     * Finds the specified regexp and adds them to the matches collection.
     *
     * @param {RegExp} regex Global regexp to search the current node by.
     * @param {Object} [data] Optional custom data element for the match.
     * @return {DomTextMatcher} Current DomTextMatcher instance.
     */
    function find(regex, data) {
      if (text && regex.global) {
        while (m = regex.exec(text)) {
          var applyMatch = 1;
          var match = createMatch(m, data);

          //console.log(data);

          // Starts with a quote
          var result = /^('+)/.exec(match.text);
          if (result) {
            var numApos = result[1].length;
            match.start += numApos;
            match.text = match.text.substring(numApos);
          }

          // Ends with a quote and (not next to an s)
          result = /[^s](['\u2019]+)$/.exec(match.text);
          if (result) {
            var numApos = result[1].length;
            match.end -= numApos;
            match.text = match.text.substring(0, match.text.length - numApos);
          }

          // Ends with a quote and (next to an apostrphe s)
          result = /['\u2019]s(['\u2019]+)$/.exec(match.text);
          if (result) {
            var numApos = result[1].length;
            match.end -= numApos;
            match.text = match.text.substring(0, match.text.length - numApos);
          }

          // Ensure this word is not within one of the SKIP-matches ranges
          for (var i = 0; i < skipMatches.length; i++) {
            var sm = skipMatches[i];
            if (match.end >= sm.start && match.start <= sm.end) {
              applyMatch = 0;
              break;
            }
          } // for

          if (applyMatch) matches.push(match);
        }
      }
      return this;
    }

    /**
     * Unwraps the specified match object or all matches if unspecified.
     *
     * @param {Object} [match] Optional match object.
     * @return {DomTextMatcher} Current DomTextMatcher instance.
     */
    function unwrap(match) {
      var i,
        elements = getWrappersByIndex(match ? indexOf(match) : null);
      i = elements.length;
      while (i--) {
        unwrapElement(elements[i]);
      }
      return this;
    }

    /**
     * Returns a match object by the specified DOM element.
     *
     * @param {DOMElement} element Element to return match object for.
     * @return {Object} Match object for the specified element.
     */
    function matchFromElement(element) {
      return matches[element.getAttribute('data-mce-index')];
    }

    /**
     * Returns a DOM element from the specified match element. This will be the first element if it's split
     * on multiple nodes.
     *
     * @param {Object} match Match element to get first element of.
     * @return {DOMElement} DOM element for the specified match object.
     */
    function elementFromMatch(match) {
      return getWrappersByIndex(indexOf(match))[0];
    }

    /**
     * Adds match the specified range for example a grammar line.
     *
     * @param {Number} start Start offset.
     * @param {Number} length Length of the text.
     * @param {Object} data Custom data object for match.
     * @return {DomTextMatcher} Current DomTextMatcher instance.
     */
    function add(start, length, data) {
      matches.push({
        start: start,
        end: start + length,
        text: text.substr(start, length),
        data: data
      });
      return this;
    }

    /**
     * Returns a DOM range for the specified match.
     *
     * @param  {Object} match Match object to get range for.
     * @return {DOMRange} DOM Range for the specified match.
     */
    function rangeFromMatch(match) {
      var wrappers = getWrappersByIndex(indexOf(match));
      var rng = editor.dom.createRng();
      rng.setStartBefore(wrappers[0]);
      rng.setEndAfter(wrappers[wrappers.length - 1]);
      return rng;
    }

    /**
     * Replaces the specified match with the specified text.
     *
     * @param {Object} match Match object to replace.
     * @param {String} text Text to replace the match with.
     * @return {DOMRange} DOM range produced after the replace.
     */
    function replace(match, text) {
      var rng = rangeFromMatch(match);
      rng.deleteContents();
      if (text.length > 0) {
        rng.insertNode(editor.dom.doc.createTextNode(text));
      }
      return rng;
    }

    /**
     * Resets the DomTextMatcher instance. This will remove any wrapped nodes and remove any matches.
     *
     * @return {[type]} [description]
     */
    function reset() {
      matches.splice(0, matches.length);
      unwrap();
      return this;
    }
    text = getText(node);
    return {
      text: text,
      matches: matches,
      each: each,
      filter: filter,
      reset: reset,
      matchFromElement: matchFromElement,
      elementFromMatch: elementFromMatch,
      find: find,
      skip: skip,
      add: add,
      wrap: wrap,
      unwrap: unwrap,
      replace: replace,
      rangeFromMatch: rangeFromMatch,
      indexOf: indexOf
    };
  }; // DomTextMatcher

  tinymce.create('tinymce.plugins.SpellcheckerPlugin', {
    getInfo: function () {
      return {
        longname: 'Spellchecker',
        author: 'Moxiecode Systems AB',
        authorurl: 'http://tinymce.moxiecode.com',
        infourl: 'http://wiki.moxiecode.com/index.php/TinyMCE:Plugins/spellchecker',
        version: "2.0.2"
      };
    },
    init: function (ed, url) {
      //# inject css into ed.settings.content_css
      let normalizedContentCss;
      if (_.isArray(ed.settings.content_css)) {
        normalizedContentCss = ed.settings.content_css;
      } else {
        normalizedContentCss = ed.settings.content_css.split(',').map(_.trim);
      }
      normalizedContentCss.push(contentStyle.default);

      //ed.settings.content_css = normalizedContentCss.concat(stylesheetUrl);
      ed.settings.content_css = normalizedContentCss;

      //ed.settings.content_style;
      //# NOTE: adding to content_style doesn't work
      //# going to use url instead

      const enabledByDefault = ed.getParam('spellchecker_enabledByDefault', DEFAULT_spellchecker_enabledByDefault);
      var t = this,
        controlManager;
      t.isActive = () => {
        return controlManager && controlManager.isActive();
        return t.button && t.button.$el.hasClass('mce-active');
      };
      t.url = url;
      t.editor = ed;

      //# terrible check to prevent the previous check_test from updating
      let checktextTimestamp;
      function promisedCheckAndMarkText() {
        const localTimestamp = new Date();
        checktextTimestamp = localTimestamp;
        return new Promise((resolve, reject) => {
          const hasContent = _.trim($(t.editor.getBody()).text()).length > 0;
          if (hasContent) {
            const words = t._getWords();
            t._callAPI('check_text.json', {
              lang: t.selectedLang,
              words: words
            }, function (r) {
              if (localTimestamp != checktextTimestamp) {
                //console.log('timestamp does not match, there is another pending request');
                return;
              }
              if (r.length > 0) {
                t._removeWords();
                t._spellchecker_active = 1;
                t._markWords(r);
                ed.nodeChanged();
              }
              resolve(r);
            });
          } else {
            resolve([]);
          }
        });
      }
      const throttled_promisedCheckAndMarkText = _.throttle(promisedCheckAndMarkText, 2000, {
        leading: false
      });
      // Register commands
      ed.addCommand(COMMAND_STRING, function () {
        controlManager.setActive(!controlManager.isActive());

        //# toggle button
        //
        if (t.isActive()) {
          ed.setProgressState(1);
          promisedCheckAndMarkText().then(r => {
            ed.setProgressState(0);
          });
        } else {
          t._done();
          t._removeWords();
        }
      });

      // Find selected language
      t.languages = {};
      each(ed.getParam('spellchecker_languages', DEFAULT_spellchecker_languages, 'hash'), function (v, k) {
        if (k.indexOf('+') === 0) {
          k = k.substring(1);
          t.selectedLang = v;
        }
        t.languages[k] = v;
      });
      var menuArr = new Array();
      each(t.languages, function (v, k) {
        var newLang = {};
        newLang.text = k;
        newLang.data = v;
        newLang.selectable = true;
        menuArr.push(newLang);
      });
      function updateSelection(e) {
        t.selectedLang = ed.settings.spellchecker_language;

        //# TODO: this is probably setting active button
        e.control.items().each(function (ctrl) {
          //ctrl.setActive(ctrl.settings.data === t.selectedLang);
        });
      }
      var buttonArgs = {
        //title: 'Check Spelling',
        tooltip: 'Spellcheck',
        icon: 'spell-check',
        onAction: () => {
          return ed.execCommand(COMMAND_STRING);
        },
        onSetup: api => {
          const ctrl = api;
          controlManager = api;
          t.button = this; // cache the button

          //# NOTE: This is a bit hacky but splitButton onClick isn't working

          /*
          ctrl.$el.on('click', function(){
              ctrl.setActive(!t.isActive());
               if (!t.isActive()) {
                  t._removeWords();
              }
          });
          */

          $(ctrl).addClass('spellchecker');
          ed.on('click', function (e) {
            t._showMenu(t, e);
          });
          ed.on('ContextMenu', function (e) {
            e.preventDefault();
          });
          ed.on('BeforeExecCommand', function (e) {
            var cmd = e.command;
            if (cmd == 'mceFullScreen') {
              // If the spell checker was active before full screen mode, keep it that way!
              var prev = t._spellchecker_active;
              t._done();
              t._spellchecker_active = prev;
            }
          });
          ed.on('KeyUp', function (e) {
            if (controlManager.isActive()) {
              throttled_promisedCheckAndMarkText();
            }
          });
        }
      };
      if (menuArr.length > 1) {
        buttonArgs.type = 'splitbutton';
        buttonArgs.menu = menuArr;
        buttonArgs.onshow = updateSelection;
        buttonArgs.onselect = function (e) {
          ed.settings.spellchecker_language = e.control.settings.data;
          t.selectedLang = ed.settings.spellchecker_language;
        };
      }
      ed.ui.registry.addToggleButton('spellchecker', buttonArgs);
      this.getLanguage = function () {
        return ed.settings.spellchecker_language;
      };

      // Set default spellchecker language if it's not specified
      ed.settings.spellchecker_language = ed.settings.spellchecker_language || ed.settings.language || 'en';
      if (enabledByDefault) {
        ed.on('init', function () {
          ed.execCommand(COMMAND_STRING, null, null, {
            skip_focus: true
          });
        });
      }
    },
    // Internal functions

    _walk: function (n, f) {
      var d = this.editor.getDoc(),
        w;
      if (d.createTreeWalker) {
        w = d.createTreeWalker(n, NodeFilter.SHOW_TEXT, null, false);
        while ((n = w.nextNode()) != null) f.call(this, n);
      } else tinymce.walk(n, f, 'childNodes');
    },
    _getSeparators: function () {
      var re = '',
        i,
        str = this.editor.getParam('spellchecker_word_separator_chars', '\\s!"#$%&()*+,-./:;<=>?@[\]^_{|}§©«®±¶·¸»¼½¾¿×÷¤\u201d\u201c\u00a0\u2019\u2018\u0027\u2014\u2013\u2026\u00ae\u00ad\u00ab\u00bb\u00b6\u20ac\u00a3');

      // Build word separator regexp
      for (i = 0; i < str.length; i++) re += '\\' + str.charAt(i);
      return re;
    },
    _getWords: function () {
      var ed = this.editor,
        wl = [],
        tx = '',
        lo = {};

      // Send back the body instead of individual words
      return "<div>" + ed.getContent() + "</div>";

      // Get area text
      this._walk(ed.getBody(), function (n) {
        if (n.nodeType == 3) tx += n.nodeValue + ' ';
      });

      // Split words by separator
      tx = tx.replace(new RegExp('([0-9]|[' + this._getSeparators() + '])', 'g'), ' ');
      tx = tinymce.trim(tx.replace(/(\s+)/g, ' '));

      // Build word array and remove duplicates
      each(tx.split(' '), function (v) {
        if (!lo[v]) {
          wl.push(v);
          lo[v] = 1;
        }
      });
      return wl;
    },
    _removeWords: function (w) {
      var ed = this.editor,
        dom = ed.dom,
        se = ed.selection.bookmarkManager;
      const b = se.getBookmark();
      each(dom.select('span').reverse(), function (n) {
        if (n && (dom.hasClass(n, 'mceItemHiddenSpellWord') || dom.hasClass(n, 'mceItemHidden'))) {
          if (!w || dom.decode(n.innerHTML) == w) dom.remove(n, 1);
        }
      });
      se.moveToBookmark(b);
    },
    removeWords: function (words) {
      this._removeWords(words);
    },
    getTextMatcher: function (reInit) {
      var self = this;
      if (reInit || !self.textMatcher) {
        self.textMatcher = new DomTextMatcher(self.editor.getBody(), self.editor);
      }
      return self.textMatcher;
    },
    _getWordCharPattern: function () {
      // Regexp for finding word specific characters this will split words by
      // spaces, quotes, copy right characters etc. It's escaped with unicode characters
      // to make it easier to output scripts on servers using different encodings
      // so if you add any characters outside the 128 byte range make sure to escape it
      var delim = "\\s!\"#$%&()*+,-./:;<=>?@[\\]^_{|}`" + "\u00a7\u00a9\u00ab\u00ae\u00b1\u00b6\u00b7\u00b8\u00bb\u2014\u2013" + "\u00bc\u00bd\u00be\u00bf\u00d7\u00f7\u00a4\u201d\u201c\u201e\u2026" + "\u2018" + "\u2012\u2011\u2015\u2212" +
      //HYPHENS
      "\u200e\u200b\u200c" +
      // Zero width spaces (for line breaking primarily)
      "\uff0c"; // Full width comma
      return this.editor.getParam('spellchecker_wordchar_pattern') || delim;
    },
    _markWords: function (wl) {
      var self = this;
      var ed = this.editor,
        dom = ed.dom,
        se = ed.selection.bookmarkManager;
      const b = se.getBookmark();

      // Added mdash as a delimiter \u2014
      // Added ndash as a delimiter \u2013
      // Added ellipses as a delimiter \u2026

      var delim = self._getWordCharPattern();
      var nonWordSeparatorCharacters = new RegExp("[^" + delim + "]+", "g");
      var skipPatterns = [new RegExp("^[" + delim + "]*(https?://|www)"), new RegExp("^.+@.+\\..+$")];

      // Find all words and make an unique words array
      self.getTextMatcher(1);
      self.getTextMatcher().skip(new RegExp("\\s+", "g"),
      // split RTE text by space
      skipPatterns // and skip emails/urls
      );

      self.getTextMatcher().find(nonWordSeparatorCharacters).each(function (match) {
        var word = match.text;
        //console.log( 'WORD: ' + word );
      });

      //self.editor.setProgressState(false);

      // Create a hash lookup of the misspelled words
      var suggestions = {};
      each(wl, function (v) {
        //console.log( 'ERR: ' + v );
        suggestions[v] = 1;
        //# update 1 to be "byAttr"
      });

      /*
      if (isEmpty(suggestions)) {
          editor.windowManager.alert('No misspellings found');
          started = false;
          return;
      }
              */

      self.lastSuggestions = suggestions;
      self.getTextMatcher().filter(function (match) {
        var token = self._preprocessToken(match.text);
        //console.log( 'Checking preprocessed  ' + token );
        var rv = !!suggestions[token];
        if (rv) {
          //console.log( 'Found match!' );
        }
        return rv;
      }).wrap(function () {
        return self.editor.dom.create('span', {
          "class": 'mceItemHiddenSpellWord',
          "data-mce-bogus": 1
        });
      });
      se.moveToBookmark(b);
      //self.editor.fire('SpellcheckStart');
    },

    markWords: function (arrayOfValues) {
      var editor = this.editor;
      this.active = 1;
      this._spellchecker_active = 1;
      this._markWords(arrayOfValues);
      editor.nodeChanged();
    },
    //# NOTE: potentially move to their own plugin specifically for link checking?
    markByAttr: function (arrayOfValues) {
      let byAttr = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "href";
      var editor = this.editor;
      this.active = 1;
      this._spellchecker_active = 1;
      var self = this;

      //# NOTE: don't need to be separate for now 
      //# since only /check_text.json is used for spellchecking
      function _markByAttr(arrayOfValues, byAttr) {
        const ed = editor,
          dom = ed.dom,
          se__bmManager = ed.selection.bookmarkManager;
        const bookmark = se__bmManager.getBookmark();
        arrayOfValues.forEach(value => {
          $(`[${byAttr}="${value}"]`, ed.getBody()).wrap(ed.dom.create('span', {
            "class": 'mceItemHiddenSpellWord',
            "data-mce-bogus": 1
          }));
        });
        se__bmManager.moveToBookmark(bookmark);
      }
      _markByAttr(arrayOfValues, byAttr);
      editor.nodeChanged();
    },
    _msie8split: function (str, separator, limit) {
      var compliantExecNpcg = /()??/.exec("")[1] === undefined; // NPCG: nonparticipating capturing group
      // msie8 and below do not handle capturing parenthesis in regexp based splits

      var output = [],
        flags = (separator.ignoreCase ? "i" : "") + (separator.multiline ? "m" : "") + (separator.extended ? "x" : "") + (
        // Proposed for ES6
        separator.sticky ? "y" : ""),
        // Firefox 3+
        lastLastIndex = 0,
        // Make `global` and avoid `lastIndex` issues by working with a copy
        separator = new RegExp(separator.source, flags + "g"),
        separator2,
        match,
        lastIndex,
        lastLength;
      str += ""; // Type-convert
      if (!compliantExecNpcg) {
        // Doesn't need flags gy, but they don't hurt
        separator2 = new RegExp("^" + separator.source + "$(?!\\s)", flags);
      }
      /* Values for `limit`, per the spec:
                   * If undefined: 4294967295 // Math.pow(2, 32) - 1
                   * If 0, Infinity, or NaN: 0
                   * If positive number: limit = Math.floor(limit); if (limit > 4294967295) limit -= 4294967296;
                   * If negative number: 4294967296 - Math.floor(Math.abs(limit))
                   * If other: Type-convert, then use the above rules
                  */
      limit = limit === undefined ? -1 >>> 0 :
      // Math.pow(2, 32) - 1
      limit >>> 0; // ToUint32(limit)
      while (match = separator.exec(str)) {
        // `separator.lastIndex` is not reliable cross-browser
        lastIndex = match.index + match[0].length;
        if (lastIndex > lastLastIndex) {
          output.push(str.slice(lastLastIndex, match.index));
          // Fix browsers whose `exec` methods don't consistently return `undefined` for
          // nonparticipating capturing groups
          if (!compliantExecNpcg && match.length > 1) {
            match[0].replace(separator2, function () {
              for (var i = 1; i < arguments.length - 2; i++) {
                if (arguments[i] === undefined) {
                  match[i] = undefined;
                }
              }
            });
          }
          if (match.length > 1 && match.index < str.length) {
            Array.prototype.push.apply(output, match.slice(1));
          }
          lastLength = match[0].length;
          lastLastIndex = lastIndex;
          if (output.length >= limit) {
            break;
          }
        }
        if (separator.lastIndex === match.index) {
          separator.lastIndex++; // Avoid an infinite loop
        }
      } // while
      if (lastLastIndex === str.length) {
        if (lastLength || !separator.test("")) {
          output.push("");
        }
      } else {
        output.push(str.slice(lastLastIndex));
      }
      return output.length > limit ? output.slice(0, limit) : output;
    },
    // _msie8split

    _preprocessToken: function ($token) {
      return $token; //# 20170616 Howard - We were previously changing the curly quotes to straight ones. and also stripping it out. DO NOT DO THAT.
    },

    // _preprocessToken

    _showMenu: function (ed, e) {
      var self = this;
      var t = this,
        ed = t.editor,
        m = t._menu,
        p1,
        dom = ed.dom,
        vp = dom.getViewPort(ed.getWin());
      var items = [];
      if (m) {
        m.remove();
      }
      if (dom.hasClass(e.target, 'mceItemHiddenSpellWord')) {
        var match = self.getTextMatcher().matchFromElement(e.target);
        t._callAPI('suggest.json', {
          lang: t.selectedLang,
          word: match.text
        }, function (r) {
          if (r[0] !== null) {
            if (r.length > 0) {
              each(r, function (v) {
                items.push({
                  text: v,
                  onclick: function () {
                    var rng = self.getTextMatcher().replace(match, v);
                    rng.collapse(false);
                    ed.selection.setRng(rng);
                    t._checkDone();
                  }
                });
              });
            }
            items.push({
              text: '-'
            });
          }
          items.push({
            title: 'spellchecker.ignore_word',
            text: 'Ignore',
            onclick: function () {
              self.getTextMatcher().unwrap(match);
              t._checkDone();
            }
          });
          items.push({
            title: 'spellchecker.ignore_words',
            text: 'Ignore All',
            onclick: function () {
              self.getTextMatcher().each(function (compareMatch) {
                if (compareMatch.text == match.text) {
                  self.getTextMatcher().unwrap(compareMatch);
                }
              });
              t._checkDone();
            }
          });
          m = new tinymce.ui.Menu({
            items: items,
            context: 'spellcheckermenu',
            'classes': 'spellcheckermenu',
            trigger: 'left',
            onautohide: function (e) {
              if (e.target.className.indexOf('spellcheckermenu') != -1) {
                e.preventDefault();
              }
            },
            onhide: function () {
              m.remove();
              m = null;
            }
          });
          m.renderTo(document.body);
          var pos = DOM.getPos(ed.getContentAreaContainer());
          var targetPos = ed.dom.getPos(e.target);
          var root = ed.dom.getRoot();

          // Adjust targetPos for scrolling in the editor
          if (root.nodeName == 'BODY') {
            targetPos.x -= root.ownerDocument.documentElement.scrollLeft || root.scrollLeft;
            targetPos.y -= root.ownerDocument.documentElement.scrollTop || root.scrollTop;
          } else {
            targetPos.x -= root.scrollLeft;
            targetPos.y -= root.scrollTop;
          }
          pos.x += targetPos.x;
          pos.y += targetPos.y;
          m.moveTo(pos.x, pos.y + e.target.offsetHeight);
        });
        return tinymce.dom.Event.cancel(e);
      }
    },
    _checkDone: function () {
      var t = this,
        ed = t.editor,
        dom = ed.dom,
        o;
      each(dom.select('span'), function (n) {
        if (n && dom.hasClass(n, 'mceItemHiddenSpellWord')) {
          o = true;
          return false;
        }
      });
      if (!o) t._done();
    },
    _done: function () {
      var t = this,
        la = t.isActive(),
        ed = t.editor;
      if (t.isActive()) {
        t._spellchecker_active = 0;
        //t._removeWords();

        if (t._menu) t._menu.hideMenu();
        if (la) ed.nodeChanged();
      }
    },
    _callAPI: function (method, param, cb) {
      var t = this,
        url = t.editor.getParam("spellchecker_api_url") || DEFAULT_spellchecker_api_url;
      if (url == '{backend}') {
        t.editor.setProgressState(0);
        alert('Please specify: spellchecker_api_url');
        return;
      }
      if (url.substr(url.length - 1, 1) != "/") url += "/";
      url += method;
      $.ajax({
        url: url,
        type: 'post',
        data: $.flatten_param(param),
        dataType: 'json',
        error: function (rsp, textStatus, errorThrown) {
          if (rsp.status) {
            t.editor.setProgressState(0);
            //# NOTE: disabling popup because the popup isn't helpful to users. They can't really do anything as a response
            //t.editor.windowManager.alert('Spellchecker API AJAX error: ' + textStatus);
          }
        },

        success: function (rsp, textStatus) {
          if (rsp.error) {
            t.editor.setProgressState(0);
            //# NOTE: disabling popup because the popup isn't helpful to users. They can't really do anything as a response
            //t.editor.windowManager.alert('Spellchecker API error: ' + rsp.error.msg);
          } else cb(rsp.data);
        }
      });
    }
  });

  // Register plugin
  tinymce.PluginManager.add('spellchecker', tinymce.plugins.SpellcheckerPlugin);
})();